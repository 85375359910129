<template>
  <b-card
      class="datacentersalespt-edit-wrapper"
  >
    <!-- form -->
    <b-form id="datacentersalesptForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="平台"
              label-for="store_company_id"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="channelArray"
                v-model="datacentersalespt.storeCompany"
                class="select-size-sm"
                @input="changeSelect('channel')"
                :disabled="id!=0"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="店铺"
              label-for="store_name"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="storeArray"
                v-model="datacentersalespt.store"
                class="select-size-sm"
                @input="changeSelect()"
                :disabled="id!=0"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="外部仓库"
              label-for="relation_name"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="whNameArray"
                v-model="datacentersalespt.relation"
                class="select-size-sm"
                @input="changeSelect()"
                :disabled="id!=0"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="仓库"
              label-for="warehouse_name"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="getCodeOptions('warehouse')"
                v-model="datacentersalespt.warehouse"
                class="select-size-sm"
                @input="changeSelect()"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="销售权团队"
              label-for="purchase_team_name"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="getCodeOptions('purchase_team')"
                v-model="datacentersalespt.purchaseTeam"
                class="select-size-sm"
                @input="changeSelect()"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import datacentersalesptStore from './datacentersalesptStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'
import axios from "@/libs/axios";
import companyStore from "@/views/apps/company/companyStore";
import storeStore from "@/views/apps/store/storeStore";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      datacentersalespt: ref({}),
      channelArray: [],
      whNameArray: [],
      storeArray:[],
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('datacentersalespt')) store.registerModule('datacentersalespt', datacentersalesptStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('datacentersalespt')) store.unregisterModule('datacentersalespt')
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('store')) store.unregisterModule('store')
    })

    const edit = function () {
      store.dispatch('datacentersalespt/edit', {id: this.id}).then(res => {
        this.datacentersalespt = res.data.data
        if (this.datacentersalespt.pt_id!=0){
          this.datacentersalespt.storeCompany = getCode('company',this.datacentersalespt.store_company_id)
          this.datacentersalespt.store = getCode('store',this.datacentersalespt.store_id)
          this.datacentersalespt.relation = this.datacentersalespt.relation_name
          this.datacentersalespt.warehouse = getCode('warehouse',this.datacentersalespt.warehouse_id)
          this.datacentersalespt.purchaseTeam = getCode('purchase_team',this.datacentersalespt.purchase_team_id)
        }
      })
    }

    const view = function () {
      store.dispatch('datacentersalespt/view', {id: this.id}).then(res => {
        this.datacentersalespt = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (isEmpty(this.datacentersalespt.storeCompany)){
        toast.error("请选择平台!")
        return ;
      }
      if (isEmpty(this.datacentersalespt.store)){
        toast.error("请选择店铺!")
        return ;
      }
      if (isEmpty(this.datacentersalespt.relation)){
        toast.error("请选择城市/外部仓库!")
        return ;
      }
      if (isEmpty(this.datacentersalespt.warehouse)){
        toast.error("请选择仓库!")
        return ;
      }
      if (isEmpty(this.datacentersalespt.purchaseTeam)){
        toast.error("请选择销售权团队!")
        return ;
      }
      this.datacentersalespt.store_company_id = this.datacentersalespt.storeCompany.value
      this.datacentersalespt.store_id = this.datacentersalespt.store.value
      this.datacentersalespt.store_name = this.datacentersalespt.store.label
      this.datacentersalespt.relation_name = this.datacentersalespt.relation
      this.datacentersalespt.warehouse_id = this.datacentersalespt.warehouse.value
      this.datacentersalespt.warehouse_name = this.datacentersalespt.warehouse.label
      this.datacentersalespt.purchase_team_id = this.datacentersalespt.purchaseTeam.value
      this.datacentersalespt.purchase_team_name = this.datacentersalespt.purchaseTeam.label
      store.dispatch('datacentersalespt/save', this.datacentersalespt).then(res => {
        if (res.data.code==0){
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-datacentersalespt-list'});
        }else {
          toast.error(res.data.data)
        }
      })
    }

    const changeSelect = function (params) {
      if (params === 'channel'){
        this.storeArray = []
        this.datacentersalespt.store = null

        store.dispatch('store/search', {company_id: isEmpty(this.datacentersalespt.storeCompany) ? null : this.datacentersalespt.storeCompany.value})
            .then(response => {
              let data = response.data.data.list
              for (let i = 0; i < data.length; i++) {
                this.storeArray.push({'key': i, "label": data[i].store_name, "value": data[i].store_id})
              }
            })
      }
      this.$forceUpdate()
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      changeSelect,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()

    store.dispatch('company/searchChannelCustomer',{state:1}).then(res => {
      const data = res.data.data.list
      for (let i = 0; i < data.length; i++) {
        this.channelArray.push({
          'key': i, "label": data[i].company_name, "value": data[i].company_id
        })
      }
    })

    store.dispatch('datacentersalespt/getCityAndWarehouse').then(res => {
      const data = res.data.data.ext
      this.whNameArray = data.whNameArray
    })

    this.storeArray = getCodeOptions('store')

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
